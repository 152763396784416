import { formatDate } from "../../helper"
import DatePicker from "../DatePicker";
import PaginationLimit from "../PaginationLimit"

export default function TopBar({
    date, setDate,
    getData, count,
    tag, setTag,
    setPage,
    limit, onChange,
}) {

    return <div className="">
        <div className="page-title">
            <div className="">available properties  <span className="count">({count})</span></div>
            <PaginationLimit limit={limit} onChange={onChange} />
        </div>
        <div className="navbar">
            <div className="mob-w-100">
                <div className="button-wrapper-heading">Tag
                </div>
                <div className="button-container input-container">
                    <input placeholder="Enter Tag" className="custom-input" value={tag}
                        onChange={(e) => {
                            setTag(e.target.value)
                            setPage(0)
                        }}
                    />
                </div>
            </div>
            <div className="checkout-container">
                <div style={{ width: "100%" }}>
                    <div className="button-wrapper-heading">Date
                        <span >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256"><path fill="currentColor" d="M208 32h-24v-8a8 8 0 0 0-16 0v8H88v-8a8 8 0 0 0-16 0v8H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16ZM72 48v8a8 8 0 0 0 16 0v-8h80v8a8 8 0 0 0 16 0v-8h24v32H48V48Zm136 160H48V96h160v112Zm-96-88v64a8 8 0 0 1-16 0v-51.06l-4.42 2.22a8 8 0 0 1-7.16-14.32l16-8A8 8 0 0 1 112 120Zm59.16 30.45L152 176h16a8 8 0 0 1 0 16h-32a8 8 0 0 1-6.4-12.8l28.78-38.37a8 8 0 1 0-13.31-8.83a8 8 0 1 1-13.85-8A24 24 0 0 1 176 136a23.76 23.76 0 0 1-4.84 14.45Z" /></svg>
                        </span>
                    </div>
                    <div className="button-container">
                        <div className={`custom-datepicker custom-datepicker-col-2`}>
                            <div style={{ textAlign: "right" }}>
                                <button style={{ width: "100%" }}
                                    onClick={() => {
                                        if (formatDate(new Date()) === formatDate(date)) {
                                            setDate(null)
                                            getData(null)
                                        } else {
                                            setDate(new Date())
                                            getData(new Date())
                                        }
                                        setPage(0)
                                    }}
                                    className={`select-box ${formatDate(new Date()) === formatDate(date) ? "active" : ""}`}>
                                    Today
                                </button>
                            </div>
                            <DatePicker labelName="check In date"
                                isClearable={true}
                                date={date}
                                setDate={(e) => {
                                    setDate(e)
                                    getData(e)
                                    setPage(0)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}