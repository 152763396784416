import { useState } from "react";
import Header from "../components/Header";
import AvailableProperties from "../components/available-properties";
import Reservations from "../components/reservations_"
export default function Index() {
    const [tab, setTab] = useState(1)
    return (<>
        <Header onSync={() => {
            const t = tab
            setTab(0)
            setTimeout(() => {
                setTab(t)
            }, 500);
        }}
            api="reservations"
        // api={tab === 1 ? "reservations" : "availabilities"}
        />

        <div className="tabs">
            {[{ id: 1, title: "Check-ins - Check-outs" }, { id: 2, title: "Flex Living Report" }].map((e, i) => {
                return <button key={e.id} onClick={() => { setTab(e.id) }} className={tab === e.id ? "active" : ""}>{e.title}</button>
            })}
        </div>
        {tab === 1 && <Reservations />}
        {tab === 2 && <AvailableProperties />}

    </>
    )
}
