export default function PaginationLimit({ limit, onChange }) {
    return <div className={`pagination-limit`}>
        <select
            value={limit}
            onChange={(e) => {
                onChange(e.target.value, 0)
            }} className="">
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={250}>250</option>
        </select>
    </div>
}