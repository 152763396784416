import { getAccountType } from "../../actions/constant"

export default function TableHead({ getReservations, sortBy, setSortBy }) {
    return <div className={`t-head t-row ${getAccountType() === "admin" ? "grid-7" : "grid-6"}`}>
        <div className="t-item">Guest name</div>
        <div className="t-item">Status</div>
        <div className="t-item">Flat booked</div>
        <Sorting getReservations={getReservations} sortBy={sortBy} setSortBy={setSortBy} />
    </div>
}

const Sorting = ({ getReservations, sortBy, setSortBy }) => {

    let SORT_DATA = [
        { title: "checkin", asc: "checkInAsc", desc: "checkInDesc" },
        { title: "checkOut", asc: "checkOutAsc", desc: "checkOutDesc" },
        { title: "creation Log", asc: "creationLogAsc", desc: "creationLogDesc" },
    ]

    if (getAccountType() === "admin") {
        SORT_DATA.push({ title: "booking value", asc: "bookingAsc", desc: "bookingDesc" })
    }

    console.log("sortBy>", sortBy)
    return (<>
        {SORT_DATA.map((e, i) => {
            return <div key={i} className={`t-item ${e.title === "booking value" ? "" : "date-item"} ${(sortBy === e.asc || sortBy === e.desc) ? "active" : ""}`}>{e.title}
                <span style={{ transform: sortBy === e.asc ? "rotateX(180deg)" : "rotateX(0deg)" }} onClick={() => {
                    if (sortBy === e.asc) {
                        setSortBy(e.desc)
                        getReservations(e.desc)
                    } else if (sortBy === e.desc) {
                        setSortBy("")
                        getReservations("")
                    } else {
                        setSortBy(e.asc)
                        getReservations(e.asc)
                    }
                }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4L2 8h3z" /></svg>
                </span>
            </div>
        })}
    </>
    )
}