import { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";

export default function DatePicker({
    date, setDate, labelName, minDate, maxDate,
    showTimeInput = false, isClearable = true
}) {
    return (<div className='' style={{ width: "100%" }}>
        <ReactDatePicker
            wrapperClassName=''
            popperClassName='!z-[99]'
            onChange={(e) => {
                setDate(e);
            }}
            minDate={minDate}
            maxDate={maxDate}
            selected={date ? new Date(date || "") : null}
            placeholderText={showTimeInput ? "dd/mm/yyyy hh:MM a" : "dd/mm/yyyy"}
            //@ts-ignore
            customInput={<CustomInput labelName={labelName} showTimeInput={showTimeInput} />}
            showMonthDropdown={true}
            dropdownMode="select"
            showYearDropdown={true}
            isClearable={isClearable}
            showTimeInput={showTimeInput}
            timeFormat="hh:MM"
        />
    </div>)
}

const CustomInput = forwardRef(({ value, onClick, labelName }, ref) => (<> <div className="select-box" onClick={onClick} ref={ref}>
    <div className="">{value || (`Select ${labelName}`)}</div>
</div>
</>
))
