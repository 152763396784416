import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { logout, getUserName, APP_NAME, API_URL, API_CONFIG } from "../actions/constant"
import axios from "axios"

export default function Header({ onSync, api }) {
    const [dropdown, setDropdown] = useState(false)
    const [syncing, setSyncing] = useState(false)
    const navigate = useNavigate()
    const userName = getUserName()

    const sync = async () => {
        try {
            setSyncing(true)
            let url = `${API_URL}${api}/sync`
            API_CONFIG.timeout = 10 * 60 * 1000
            await axios.get(url, API_CONFIG)
            setTimeout(() => {
                onSync()
                setSyncing(false)
            }, 2.5 * 60 * 1000);
        } catch (e) {
            setSyncing(false)
        }
    }
    return (<><header>
        <div className="header-wrapper">
            <div className="logo">{APP_NAME}</div>
            {/* {getAccountType() === "admin" &&  */}
            <div className="button-wrapper">
                <button onClick={sync}
                    disabled={syncing}
                > {!syncing ? "Sync" : "Syncing"}
                </button>
            </div>
            {/* } */}
            <div className="profile">
                <div className="profile-wrapper">
                    <div className="profile-name" onClick={() => { setDropdown(!dropdown) }}>{dropdown ?
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24">
                            <path fill="currentColor"
                                d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6L6.4 19Z" />                        </svg>
                        : (userName && userName[0])}</div>
                    {dropdown && <ul>
                        <li className="user-name">{userName}</li>
                        <li className="log-out" onClick={() => {
                            logout()
                            navigate("/login")
                        }}>Log Out</li>
                    </ul>}
                </div>
            </div>
        </div >
    </header >
        <div className="placeholder"></div>
    </>
    )
}