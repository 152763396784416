import { getAccountType } from "../../actions/constant";
import { formatDate } from "../../helper"
import DatePicker from "../DatePicker"
import PaginationLimit from "../PaginationLimit"

let timeOut = null;

export default function TopBar({
    checkOutDay, setCheckOutDay,
    getReservations, getReservationsCount, count,
    checkIn, setCheckIn,
    create, setCreate,
    tag, setTag,
    city, setCity,
    limit, page, sortBy, onChange

}) {

    const searchTag = (e) => {
        if (timeOut) {
            clearTimeout(timeOut)
        }
        timeOut = setTimeout(() => {
            getReservations(limit, page, checkOutDay, sortBy, checkIn, create, e.target.value, city)
            getReservationsCount(checkOutDay, checkIn, create, e.target.value, city)
        }, 1000);
    }

    const searchCity = (e) => {
        if (timeOut) {
            clearTimeout(timeOut)
        }
        timeOut = setTimeout(() => {
            getReservations(limit, page, checkOutDay, sortBy, checkIn, create, tag, e.target.value)
            getReservationsCount(checkOutDay, checkIn, create, tag, e.target.value)
        }, 1000);
    }

    const today = new Date()
    const todayFormatedDate = formatDate(new Date())
    let tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    let thirdDay = new Date(tomorrow)
    thirdDay.setDate(tomorrow.getDate() + 1)

    let fourthDay = new Date(thirdDay)
    fourthDay.setDate(thirdDay.getDate() + 1)

    const DAYS = [{ id: 0, name: "Today" }, { id: 1, name: tomorrow }, { id: 2, name: thirdDay }, { id: 3, name: fourthDay }]

    return <div className="">
        <div className="page-title">
            <div className="">Reservations <span className="count">({count})</span></div>
            <PaginationLimit limit={limit} onChange={onChange} />
        </div>
        <div className="navbar">
            <div className="mob-w-100">
                <div className="button-wrapper-heading">Check In
                    <span style={{ transform: "rotateY(180deg)" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24"><path fill="currentColor" d="M19.002 3h-14c-1.103 0-2 .897-2 2v4h2V5h14v14h-14v-4h-2v4c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.898-2-2-2z" />
                            <path fill="currentColor" d="m11 16l5-4l-5-4v3.001H3v2h8z" />
                        </svg>
                    </span>
                </div>
                <div className="button-container">
                    <div className={`custom-datepicker ${getAccountType() === "admin" ? "custom-datepicker-col-3" : "custom-datepicker-col-2"}`}>
                        <div style={{ textAlign: "right" }}>
                            <button
                                onClick={() => {
                                    const date = new Date()
                                    if (todayFormatedDate === formatDate(checkIn)) {
                                        setCheckIn(null)
                                        getReservations(limit, page, checkOutDay, sortBy, null, create, tag, city)
                                        getReservationsCount(checkOutDay, null, create, tag, city)
                                    } else {
                                        setCheckIn(date)
                                        getReservations(limit, page, checkOutDay, sortBy, date, create, tag, city)
                                        getReservationsCount(checkOutDay, date, create, tag, city)
                                    }
                                }}
                                className={`select-box ${todayFormatedDate === formatDate(checkIn) ? "active" : ""}`}>
                                Today
                            </button>
                        </div>
                        <DatePicker labelName="check In date"
                            isClearable={true}
                            date={checkIn}
                            setDate={(e) => {
                                setCheckIn(e)
                                getReservations(limit, page, checkOutDay, sortBy, e, create, tag, city)
                                getReservationsCount(checkOutDay, e, create, tag, city)

                            }} />
                        {getAccountType() === "admin" && <DatePicker labelName="creation log"
                            isClearable={true}
                            date={create}
                            setDate={(e) => {
                                setCreate(e)
                                getReservations(limit, page, checkOutDay, sortBy, checkIn, e, tag, city)
                                getReservationsCount(checkOutDay, create, e, tag, city)
                            }} />}
                    </div>
                </div>
            </div>
            <div className="mob-w-100">
                <div className="button-wrapper-heading">Search
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="currentColor" d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.612 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3l-1.4 1.4ZM9.5 14q1.875 0 3.188-1.313T14 9.5q0-1.875-1.313-3.188T9.5 5Q7.625 5 6.312 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14Z" /></svg>                    </span>
                </div>
                <div className="button-container reservations-topbar-container">
                    <div className={`custom-datepicker custom-datepicker-col-2`}>
                        <input placeholder="Enter Tag" className="custom-input" value={tag}
                            onChange={(e) => {
                                setTag(e.target.value)
                                searchTag(e)
                            }} />
                        <input placeholder="Enter City" className="custom-input" value={city}
                            onChange={(e) => {
                                setCity(e.target.value)
                                searchCity(e)
                            }} />
                    </div>
                </div>
            </div>
            <div className="checkout-container">
                <div style={{ width: "100%" }}>
                    <div className="button-wrapper-heading">Check Out
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24"><path fill="currentColor" d="M19.002 3h-14c-1.103 0-2 .897-2 2v4h2V5h14v14h-14v-4h-2v4c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.898-2-2-2z" />
                                <path fill="currentColor" d="m11 16l5-4l-5-4v3.001H3v2h8z" />
                            </svg>
                        </span>
                    </div>
                    <div className="button-container">
                        <div className="button-wrapper">
                            {DAYS.map((e, i) => {
                                return <button key={e.id} onClick={() => {
                                    if (checkOutDay === e.id) {
                                        getReservations(limit, page, null, sortBy, e.id === 0 ? null : checkIn, create, tag, city)
                                        getReservationsCount(null, e.id === 0 ? null : checkIn, create, tag, city)
                                        setCheckOutDay("")
                                    } else {
                                        getReservations(limit, page, e.id, sortBy, e.id === 0 ? null : checkIn, create, tag, city)
                                        getReservationsCount(e.id, e.id === 0 ? null : checkIn, create, tag, city)
                                        setCheckOutDay(e.id)
                                    }
                                }}
                                    className={`${checkOutDay === e.id ? "active" : ""}`}>
                                    {e.name !== "Today" ? formatDate(e.name) : e.name}
                                </button>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div >
}