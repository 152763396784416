export default function TableHead({ getData, sortBy, setSortBy }) {
    return <div className={`t-head t-row available-properties-row`}>
        <div className="t-item">Property</div>
        <div className="t-item">Availablity</div>
        <Sorting getData={getData} sortBy={sortBy} setSortBy={setSortBy} />
    </div>
}

const Sorting = ({ getData, sortBy, setSortBy }) => {

    let SORT_DATA = [
        { title: "current checkin", asc: "checkInAsc", desc: "checkInDesc" },
        { title: "current checkOut", asc: "checkOutAsc", desc: "checkOutDesc" },
        { title: "next checkin", asc: "nextCheckInAsc", desc: "nextCheckInDesc" },
        { title: "next checkOut", asc: "nextCheckOutAsc", desc: "nextCheckOutDesc" },
    ]
    return (<>
        {SORT_DATA.map((e, i) => {
            return <div key={i} className={`t-item  ${(sortBy === e.asc || sortBy === e.desc) ? "active" : ""}`}>{e.title}
                <span style={{ transform: sortBy === e.asc ? "rotateX(180deg)" : "rotateX(0deg)" }} onClick={() => {
                    if (sortBy === e.asc) {
                        setSortBy(e.desc)
                        // getData(e.desc)
                    } else {
                        setSortBy(e.asc)
                        // getData(e.asc)
                    }
                }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4L2 8h3z" /></svg>
                </span>
            </div>
        })}
    </>
    )
}