const API_URL = process.env.REACT_APP_API
const APP_NAME = process.env.REACT_APP_NAME

const getAccessToken = function getAccessToken() {
    const token = localStorage.getItem("token");
    if (!token) {
        return null
    }
    return `Bearer ${token}`
};

const API_CONFIG = {
    headers: {
        "Authorization": getAccessToken(),
    }
}

const getUserName = function getUserName() {
    return localStorage.getItem("name");
};


const getUserEmail = function getUserEmail() {
    return localStorage.getItem("email");
};

const getUserId = function getUserId() {
    return localStorage.getItem("id");
}
const getToken = function getToken() {
    return localStorage.getItem("token");
};

const getAccountType = function getAccountType() {
    return localStorage.getItem("accountType");
};

const logout = function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("id");
    localStorage.removeItem("name");
    localStorage.removeItem("accountType");
};


export {
    API_URL,
    APP_NAME,
    getUserName,
    getAccessToken,
    getUserId,
    getUserEmail,
    getToken,
    logout,
    getAccountType,
    API_CONFIG
};
