/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './styles.css';
import "react-datepicker/dist/react-datepicker.css";
import Login from './pages/login';
import Index from './pages';

function App() {
  return (<>
    <BrowserRouter>
      <Routing />
    </BrowserRouter>
  </>);
}

export default App;


const Routing = () => {
  return <Routes>
    <Route>
      <Route path="/" element={<Index />} />
      <Route path="login" element={<Login />} />
    </Route>
  </Routes>
}