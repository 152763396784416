export default function Status({ status }) {
    if (!status) {
        return null
    }

    const getStatus = (s) => {
        if (s === "done") {
            return "#4CAF50"
        }
        if (s === "no show") {
            return "#ffc107"
        }
        if (s === "cancelled") {
            return "#f44336"
        }
        return "#000"
    }
    return <span className="badge" style={{ backgroundColor: getStatus(status.toLowerCase()) }} >{status}</span>
}