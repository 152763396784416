import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getError } from "../helper";
import axios from "axios";

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [type, setType] = useState("password")
    const [sending, setSending] = useState(false)
    const navigate = useNavigate()

    const handleClick = () => {
        setType(type === "password" ? "text" : "password")
    }
    const login = async () => {
        try {
            if (!email) {
                setError("Email Required")
                return
            }
            if (!password) {
                setError("Password Required")
                return
            }
            if (password.length < 6) {
                setError("Password must be more than or equal to 6 characters")
                return
            }
            setSending(true)
            const response = await axios.post(`${process.env.REACT_APP_API}auth/login`, { email, password })
            const data = response?.data?.data
            if (!data) {
                setError(response.message)
                setSending(false)
            } else {
                if (data.token) {
                    localStorage.setItem("token", data.token)
                    localStorage.setItem("email", data.email)
                    localStorage.setItem("name", data.name)
                    localStorage.setItem("id", data._id)
                    localStorage.setItem("accountType", data.accountType)
                    navigate("/")
                    window.location.reload(true)
                } else {
                    setError("Try Again")
                }
            }
            setSending(false)

        } catch (error) {
            setError(getError(error))
            setSending(false)
        }
    }
    return <div className="login">
        <div className="login-container">
            <div className="wrapper">
                <h2>Login</h2>
                <div className="form">
                    <div className="input-box">
                        <input type="email" placeholder="Enter your email"
                            value={email} onChange={(e) => {
                                setError("")
                                setEmail(e.target.value)
                            }} />
                    </div>
                    <div className="input-box password-input">
                        <input type={type} placeholder="password"
                            value={password} onChange={(e) => {
                                setError("")
                                setPassword(e.target.value)
                            }} />
                        <span className="password__show" onClick={handleClick}>{type === 'text' ? "hide" : "show"}</span>
                    </div>
                    {error && <div className="error">{error}</div>}

                    <div className="input-box">
                        <button disabled={sending} type="button" onClick={() => { login() }}>Register Now</button>
                    </div>
                </div>

            </div>
            <div className="app-label">
                <h1>
                    Views by Flex Living
                </h1>
            </div>
        </div>
    </div>
}