import { format } from "date-fns";

export function formatDate(date) {
    if (!date)
        return ""
    try {
        let d = date
        if (typeof date === "string")
            d = new Date(date);

        return format(d, 'LLL d, yyyy');
    } catch (e) {
        return ""
    }
}

export function getQuery(limit, page) {
    const query = {}
    query.limit = limit
    query.skip = page * limit

    var queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
    return queryString
}

export function getError(error) {
    if (!error) {
        return "Something went wrong"
    } else if (typeof error === "string") {
        return error
    } else {
        return error.response?.data?.message || error.response?.message || error.message || `${error}`
    }
}


export const range = (from, to, step) =>
    [...Array(Math.floor((to - from) / step) + 1)].map((_, i) => from + i * step)
