/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios"
import { API_CONFIG, API_URL, getToken } from "../../actions/constant"
import Loading from "../Loading"
import NoData from "../NoData"
import Pagination from "../Pagination"
import ReservationsTable from "./Table"
import TopBar from "./TopBar"
import { getQuery } from "../../helper"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { logout } from "../../actions/constant"
export default function Reservations() {
    const [checkOutDay, setCheckOutDay] = useState(null)
    const [reservations, setReservations] = useState([])
    const [loading, setLoading] = useState(true)
    const [limit, setLimit] = useState(20)
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)
    const [sortBy, setSortBy] = useState("")
    const [checkIn, setCheckIn] = useState(null)
    const [create, setCreate] = useState(null)
    const [tag, setTag] = useState(null)
    const [city, setCity] = useState(null)


    const navigate = useNavigate()
    const token = getToken()

    useEffect(() => {
        if (token) {
            getReservations(limit, page, checkOutDay, sortBy, checkIn, create, tag, city)
            getReservationsCount(checkOutDay, checkIn, create, tag, city)
        } else {
            navigate("/login")
        }
    }, [limit, page])

    const getReservations = async (limit, page, checkOutDay, sortBy, checkIn, create, tag, city) => {
        setLoading(true)
        try {
            let url = `${API_URL}reservations?&${getQuery(limit, page)}`
            url = url + "&day=" + (checkOutDay === null ? "" : checkOutDay)
            if (sortBy) {
                url = url + "&sort=" + sortBy
            }
            if (checkIn) {
                url = url + "&checkin=" + checkIn
            }
            if (create) {
                url = url + "&create=" + create
            }
            if (tag) {
                url = url + "&tag=" + tag
            }
            if (city) {
                url = url + "&city=" + city
            }
            const response = await axios.get(url, API_CONFIG)
            setReservations(response.data.data || [])
            setLoading(false)
        } catch (e) {
            if (e.response && e.response.status === 401) {
                logout()
                window.location.reload(true)
            }
            setLoading(false)
            console.log(e)
        }
    }

    const getReservationsCount = async (checkOutDay, checkIn, create, tag, city) => {
        try {
            let url = `${API_URL}reservations/count?`
            url = url + "&day=" + (checkOutDay === null ? "" : checkOutDay)
            if (checkIn) {
                url = url + "&checkin=" + checkIn
            }
            if (create) {
                url = url + "&create=" + create
            }
            if (tag) {
                url = url + "&tag=" + tag
            }
            if (city) {
                url = url + "&city=" + city
            }
            const response = await axios.get(url, API_CONFIG)
            setCount(response.data.data)
        } catch (e) {
            console.log(e)
        }
    }




    return <div className="page-wrapper">
        <TopBar onChange={(limit_, page_) => {
            if (limit_ !== limit) {
                setLimit(limit_)
            }
            if (page_ !== page) {
                setPage(page_)
            }
            getReservations(limit_, page_, checkOutDay, sortBy, checkIn, create, tag, city)
        }} checkOutDay={checkOutDay} setCheckOutDay={setCheckOutDay}
            count={count}
            getReservations={getReservations}
            getReservationsCount={getReservationsCount}
            checkIn={checkIn} setCheckIn={setCheckIn}
            create={create} setCreate={setCreate}
            tag={tag} setTag={setTag}
            city={city} setCity={setCity}
            limit={limit} page={page} sortBy={sortBy}
        />

        {loading ? <div className="loading"><Loading /></div> : (
            reservations && reservations.length > 0 ? <>
                <ReservationsTable reservations={reservations} sortBy={sortBy} setSortBy={setSortBy}
                    getReservations={(e) => {
                        getReservations(limit, page, checkOutDay, e, checkIn, create, tag, city)
                    }}
                />

                <Pagination limit={limit} page={page} count={count}
                    onChange={(limit_, page_) => {
                        if (limit_ !== limit) {
                            setLimit(limit_)
                        }
                        if (page_ !== page) {
                            setPage(page_)
                        }
                        getReservations(limit_, page_, checkOutDay, sortBy, checkIn, create, tag, city)
                    }} />
            </> : <NoData />)}
    </div>

}