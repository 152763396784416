import React from "react";
import PaginationLimit from "./PaginationLimit";

export default function Pagination(props) {
    const { limit, page, count, onChange } = props
    let total = parseInt(count / limit)

    if (total * limit !== count) {
        total = total + 1
    }

    return (
        <div className="pagination" style={{ gap: 12 }}>
            <div className="pagination-wrapper" style={{ gap: 6 }}>
                {Array(total).fill("0").map((e, index) =>
                    <span
                        key={index}
                        onClick={() => { onChange(limit, index) }}
                        className={`${index === page ? "active" : ""}`}
                    >{index + 1}</span>
                )}
            </div>
            <PaginationLimit limit={limit} onChange={onChange} />
        </div>
    )
}