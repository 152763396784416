import { formatDate } from "../../helper"

export default function TableRow({ i, e }) {
    return <div key={i} className={`t-row available-properties-row`}>
        <div className="t-item g-name first-item">
            <span className="">{e["property"]}</span>
            <span className="d-none">
                {e["availability"]}
            </span></div>
        <div className="t-item mob-d-none">
            {e["availability"]}
        </div>
        <div className="t-item"><span className="t-item-label">current checkin: </span><span>{formatDate(e["current_checkin"])}</span></div>
        <div className="t-item"><span className="t-item-label">current checkout: </span><span>{formatDate(e["current_checkout"])}</span></div>
        <div className="t-item"><span className="t-item-label">next checkin: </span><span>{formatDate(e["next_checkin"]) || "N/A"}</span></div>
        <div className="t-item"><span className="t-item-label">next checkout: </span><span>{formatDate(e["next_checkout"]) || "N/A"}</span></div>
    </div>
}
