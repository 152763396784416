/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios"
import { API_CONFIG, API_URL, getToken } from "../../actions/constant"
import Loading from "../Loading"
import NoData from "../NoData"
import Pagination from "../Pagination"
import Table from "./Table"
import TopBar from "./TopBar"
import { getQuery } from "../../helper"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export default function AvailableProperties() {
    const [date, setDate] = useState(new Date())
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [limit, setLimit] = useState(20)
    const [page, setPage] = useState(0)
    // const [count, setCount] = useState(0)
    const [sortBy, setSortBy] = useState("")
    const [tag, setTag] = useState("")

    const navigate = useNavigate()
    const token = getToken()

    useEffect(() => {
        if (token) {
            getData(date)
            // getCounts(date, tag)
        } else {
            navigate("/login")
        }
    }, [])

    const getData = async (date) => {
        setLoading(true)
        try {
            let url = `${API_URL}availabilities?`
            url = url + "&date=" + (date === null ? "" : date)
            // if (sortBy) {
            //     url = url + "&sort=" + sortBy
            // }
            // if (tag) {
            //     url = url + "&tag=" + tag
            // }

            const response = await axios.get(url, API_CONFIG)
            setData(response.data.data)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }

    // const getCounts = async (date, tag) => {
    //     try {
    //         let url = `${API_URL}availabilities/count?`
    //         url = url + "&day=" + (date === null ? "" : date)
    //         if (tag) {
    //             url = url + "&tag=" + tag
    //         }

    //         const response = await axios.get(url, API_CONFIG)
    //         setCount(response.data.data)
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }


    const tag_ = tag.toLowerCase()
    let filterData = data
    if (tag)
        filterData = filterData.filter(e => e.tag.toLowerCase().includes(tag_))

    if (date)
        filterData = filterData.filter(e => e.availability.toLowerCase() === 'available')

    let count = filterData.length

    let sortedData = filterData

    if (sortBy === "checkInAsc") {
        sortedData = sortedData.sort(function (a, b) {
            return new Date(a.current_checkin) - new Date(b.current_checkin)
        })
    } else if (sortBy === "checkInDesc") {
        sortedData = sortedData.sort(function (a, b) {
            return new Date(b.current_checkin) - new Date(a.current_checkin)
        })
    } else if (sortBy === "checkOutAsc") {
        sortedData = sortedData.sort(function (a, b) {
            return new Date(a.current_checkout) - new Date(b.current_checkout)
        })
    } else if (sortBy === "checkOutDesc") {
        sortedData = sortedData.sort(function (a, b) {
            return new Date(b.current_checkout) - new Date(a.current_checkout)
        })
    } else if (sortBy === "nextCheckInAsc") {
        sortedData = sortedData.sort(function (a, b) {
            return new Date(a.next_checkin) - new Date(b.next_checkin)
        })
    } else if (sortBy === "nextCheckInDesc") {
        sortedData = sortedData.sort(function (a, b) {
            return new Date(b.next_checkin) - new Date(a.next_checkin)
        })
    } else if (sortBy === "nextCheckOutAsc") {
        sortedData = sortedData.sort(function (a, b) {
            return new Date(a.next_checkout) - new Date(b.next_checkout)
        })
    } else if (sortBy === "nextCheckOutDesc") {
        sortedData = sortedData.sort(function (a, b) {
            return new Date(b.next_checkout) - new Date(a.next_checkout)
        })
    }

    const pageData = sortedData.filter((e, i) => i >= (page * limit) && i < ((page + 1) * limit))


    return <div className="page-wrapper">
        <TopBar
            onChange={(limit_, page_) => {
                if (limit_ !== limit) {
                    setLimit(limit_)
                }
                if (page_ !== page) {
                    setPage(page_)
                }
                // getData(date)
            }}
            date={date} setDate={setDate}
            count={count}
            getData={getData}
            // getCounts={getCounts}
            tag={tag} setTag={setTag}
            page={page} setPage={setPage}
            limit={limit} sortBy={sortBy}
        />

        {loading ? <div className="loading"><Loading /></div> : (
            pageData && pageData.length > 0 ? <>
                <Table data={pageData} sortBy={sortBy} setSortBy={setSortBy}
                    getData={(e) => {
                        // getData(limit, page, date, e, tag)
                    }}
                />

                <Pagination limit={limit} page={page} count={count}
                    onChange={(limit_, page_) => {
                        if (limit_ !== limit) {
                            setLimit(limit_)
                        }
                        if (page_ !== page) {
                            setPage(page_)
                        }
                        // getData(limit_, page_, date, sortBy, tag)
                    }}
                />
            </> : <NoData />)}
    </div>

}