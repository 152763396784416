import { differenceInDays, startOfDay } from "date-fns"
import { getAccountType } from "../../actions/constant"
import { formatDate } from "../../helper"
import Status from "./Status"

export default function TableRow({ i, e }) {
    const getClass = () => {
        const date = e.checkInDate
        if (!date)
            return ""

        if (differenceInDays(startOfDay(new Date(date)), startOfDay(new Date())) === 0)
            return "first-item-checkin"
    }
    const getCheckInDate = () => {
        const date = e.checkInDate
        if (!date)
            return ""

        if (differenceInDays(startOfDay(new Date(date)), startOfDay(new Date())) === 0)
            return "Today"
        return formatDate(date)
    }

    const getCheckOutDate = () => {
        const date = e.checkOutDate
        if (!date)
            return ""
        const diff = differenceInDays(startOfDay(new Date(date)), startOfDay(new Date()))
        if (diff === 0) {
            return "Today"
        } else if (diff === 1) {
            return "Tomorrow"
        } else if (diff === 2) {
            return "In 2 Days"
        } else if (diff === 3) {
            return "In 3 Days"
        }
        return formatDate(date)
    }
    const getOutColor = () => {
        const date = e.checkOutDate
        if (!date)
            return ""
        const diff = differenceInDays(startOfDay(new Date(date)), startOfDay(new Date()))
        if (diff === 0) {
            return "#cca300"
        } else if (diff === 1) {
            return "#e6b800"
        } else if (diff === 2) {
            return "#ffd11a"
        } else if (diff === 3) {
            return "#ffdb4d"
        }
        return ""
    }
    return <div key={i} className={`t-row ${getAccountType() === "admin" ? "grid-7" : "grid-6"}`}>
        <div className="t-item g-name first-item"><span className="">{e.guest}</span><span className="d-none">
            <Status status={e.status} />
        </span></div>
        <div className="t-item mob-d-none">
            <Status status={e.status} />
        </div>
        <div className="t-item"><span className="t-item-label">Flat booked: </span><span>{e.flatBooked}</span></div>
        <div className={`t-item date-item ${getClass()}`}><span className="t-item-label"> Checkin date: </span><span>{getCheckInDate()}</span></div>
        <div className="t-item date-item" style={{ backgroundColor: getOutColor() }}><span className="t-item-label">Checkout date: </span><span>{getCheckOutDate()}</span></div>
        <div className="t-item date-item"><span className="t-item-label">Creation log: </span><span>{formatDate(e.creationLog)}</span></div>
        {getAccountType() === "admin" && <div className="t-item"><span className="t-item-label">Booking value: </span><span>{e.bookingValue}</span></div>}
    </div>
}
