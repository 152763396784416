import TableHead from "./TableHead"
import TableRow from "./TableRow"

export default function Table({ data, getData, sortBy, setSortBy }) {
    return <div className="table">
        <TableHead getData={getData} sortBy={sortBy} setSortBy={setSortBy} />
        <div className="t-body">
            {data.map((e, i) => {
                return <TableRow e={e} i={i} key={i} />
            })}
        </div>
    </div>
}